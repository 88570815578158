<template>
  <div>
    <component
      :is="searchComponent"
      @change="toggleOption"
      v-bind="{ options }"
    />
    <form
      :class="{ 'box has-background-white': $mq !== 'mobile' }"
      @submit.prevent="submit"
    >
      <div
        class="field"
        :class="{ 'has-addons p-2': $mq !== 'mobile' }"
      >
        <div
          class="control is-expanded"
          :class="[$mq === 'mobile' ? 'field' : 'has-icons-right pr-2']"
        >
          <input
            data-test="vrm-vin-input"
            class="input is-size-6 is-soft"
            v-model="value"
            type="text"
            v-validate="validation"
            placeholder="Search by VRM or VIN"
            name="value"
            maxlength="17"
          >
          <span
            class="icon is-right is-size-6"
            v-if="$mq !== 'mobile'"
          >
            <span
              class="heading is-marginless has-text-success is-size-7 has-text-weight-bold"
              v-if="search.value.length > 0 && search.value.length <= 7"
            >VRM</span>
            <span
              class="heading is-marginless has-text-success is-size-7 has-text-weight-bold"
              v-else-if="search.value.length > 0 && search.value.length <= 17"
            >VIN</span>
          </span>
        </div>
        <div
          class="control is-expanded"
          :class="{ field: $mq === 'mobile' }"
          v-if="action !== 'vpp'"
        >
          <div
            class="field has-addons columns is-gapless"
            :class="[
              $mq === 'mobile'
                ? 'is-flex-direction-column'
                : 'is-flex-direction-row'
            ]"
          >
            <div
              v-if="options[0].isSelected && hasCustomerReference"
              class="control is-small is-expanded column"
              :class="[$mq === 'mobile' ? 'mb-3' : 'mr-2']"
            >
              <input
                data-test="customerReference-input"
                class="input is-small is-size-6 is-soft"
                v-model="search.customerReference"
                placeholder="Add a reference"
                name="customerReference"
                maxlength="30"
              >
            </div>
            <div
              id="inputs-group-mileage-date-picker"
              class="control is-expanded is-medium is-flex column"
              :class="[
                $mq === 'mobile' && hasCustomerReference ? 'mb-3' : 'mt-0'
              ]"
            >
              <input
                data-test="mileage-input"
                class="input is-medium is-size-6 is-soft mr-2"
                v-model.number="search.mileage"
                type="number"
                v-validate="'numeric|min:0|max:500000'"
                placeholder="Enter mileage"
                name="mileage"
                min="0"
                max="500000"
              >
              <div
                class="control"
                :class="{ 'pr-2': $mq !== 'mobile' }"
                @click="track(false)"
              >
                <span
                  class="button is-medium is-soft is-outlined has-background-white"
                  @click.prevent="toggleFilter"
                  v-if="hasDate"
                >
                  <span
                    class="icon calendar-colour"
                    data-test="date-input-button"
                  >
                    <i class="fal fa-calendar-check" />
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="button is-size-6 is-info"
          :class="[
            { 'is-loading': $wait.is('search') },
            { 'is-fullwidth': $mq === 'mobile' }
          ]"
          :disabled="!this.type || $wait.is('search')"
        >
          Search
        </button>
      </div>
      <p
        class="is-size-7"
        :class="{ 'px-2': $mq !== 'mobile' }"
      >
        <span v-if="action !== 'vpp'">
          If unspecified, we will estimate the mileage for you
        </span>
        <span v-else>&nbsp;</span>
      </p>
    </form>
    <transition
      enter-active-class="animated faster zoomIn"
      leave-active-class="animated faster zoomOut"
    >
      <TableFilter
        @save="search.date = $event.date"
        @close="toggleFilter"
        :date-min="dateRange.min"
        :date-max="dateRange.max"
        :is-companion="true"
        v-if="filterIsActive"
      />
    </transition>
  </div>
</template>

<script>
import * as autocheck from 'modules/autocheck/services'
import * as companion from 'modules/companion/services'
import * as provenance from 'modules/provenance/services'
import * as layout from 'modules/layout/services'
import { formatDate } from '@/utils/date.utils.ts'
import { mapGetters } from 'vuex'
const $motors = process.env.VUE_APP_TYPE === 'motors'
const $theme = process.env.VUE_APP_TYPE
export default {
  name: 'DashboardSearch',
  data: () => ({
    filterIsActive: false,
    search: {
      value: '',
      mileage: null,
      date: null,
      customerReference: null
    },
    options: [
      {
        title: $motors ? 'Price check' : 'Companion',
        action: 'companion',
        isSelected: true, 
        theme: $theme,
        permission: true,
      },
      {
        title: $motors ? 'History check' : 'AutoCheck',
        action: 'check',
        icon: 'fas fa-check',
        isSelected: false,
        theme: $theme,
        permission: 'hasAutoCheck',
      },
      {
        title: 'MotorCheck',
        action: 'motorcheck',
        icon: 'fas fa-check',
        isSelected: false,
        theme: $theme,
        permission: 'hasMotorCheck',
      }
      // REMOVE VEHICLE PROFILE FROM DASHBOARD SEARCH
      // {
      //   title: 'Vehicle Profile',
      //   action: 'vpp',
      //   icon: 'fas fa-search',
      //   isSelected: false,
      //   permission: false,
      // }
    ],
  }),
  components: {
    TableFilter: () =>
      import('core/modules/table-filter/components/TableFilter'),
    DashboardSearchOptions: () => import('./DashboardSearchOptions'),
    DashboardSearchOptionsMobile: () => import('./DashboardSearchOptionsMobile'),
    Precheck: () => import('modules/provenance/modal/precheck')
  },
  watch: {
    date() {
      return this.track(this.date)
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isCompanionUser',
      'hasAutoCheck',
      'hasMotorCheck',
      'hasCustomerReference'
    ]),
    dateRange: () => layout.dateRange(),
    date() {
      return this.search.date
    },
    isVin() {
      return this.type === 'vin'
    },
    isVrm() {
      return this.type === 'vrm'
    },
    value: {
      get() {
        return this.search.value
      },
      set(value) {
        this.search.value = value.trim().replace(' ', '').toUpperCase()
      }
    },
    type() {
      const { length } = this.search.value
      if (length > 0 && length <= 7) return 'vrm'
      else if (length > 0 && length <= 17) return 'vin'
      else return null
    },
    validation() {
      return this.isVin ? 'alpha_dash|min:1|max:17' : 'alpha_num|min:1|max:17'
    },
    errorMessage() {
      if (this.errors.first('value') && this.errors.first('mileage')) {
        return 'Please enter a valid VRM or VIN and a valid mileage'
      } else if (this.errors.first('value')) {
        return 'Please enter a valid VRM or VIN'
      } else return 'Please enter a valid mileage composed of numbers only'
    },
    searchComponent() {
      return this.$mq === 'mobile'
        ? 'DashboardSearchOptionsMobile'
        : 'DashboardSearchOptions'
    },
    action() {
      return this.options.find((option) => option.isSelected).action
    },
    hasDate() {
      return (
        this.$store.getters['auth/isInsurer'] && this.action === 'companion'
      )
    }
  },
  methods: {
    formatDate,
    toggleFilter() {
      this.filterIsActive = !this.filterIsActive
    },
    track(date) {
      if (date) this.$mxp.track(`dashboard_${this.action}_select_date`)
      else this.$mxp.track('dashboard_toggle_datepicker')
    },
    async submit() {
      this.$mxp.track(`dashboard_${this.action}_search`)
      this.$wait.start('search')
      try {
        const valid = await this.$validator.validate()
        if (valid) {
          const hasPermission = this.checkUserPermission(this.action)

          if (hasPermission) {
            return await this[this.action]()
          } else if (this.action === 'companion') {
            this.$modal.open('companion/UpsellCompanion')
          }
        } else if (this.action !== 'companion') {
          this.fail(this.errorMessage)
        }
      } catch (err) {
        const message = err?.response?.data?.error?.message || 'Sorry there has been an error'
        this.fail(message)
      } finally {
        this.$wait.end('search')
      }
    },
    checkUserPermission(actionType) {
      const { isCompanionUser, hasAutoCheck, hasMotorCheck } = this
      if (actionType === 'companion') {
        return isCompanionUser
      } else if (actionType === 'check') {
        return hasAutoCheck
      } else if (actionType === 'motorcheck') {
        return hasMotorCheck
      } else {
        return false
      }
    },
    async vpp() {
      try {
        const { isVin, isVrm, value, type, search } = this
        const data = isVin ? await autocheck.precheck({ value, type }) : null
        const vrm = isVrm ? search.value : data.vrm
        this.$router
          .push({ name: 'vehicle-profile', params: { vrm } })
          .catch(() => {})
      } catch (err) {
        throw err
      }
    },
    async companion() {
      const { isVin, isVrm, value, type, search } = this
      const data = isVin ? await autocheck.precheck({ value, type }) : null
      const vrm = isVrm ? search.value : data.vrm
      const mileage = search.mileage ? search.mileage : null
      const customerReference = search.customerReference
      const params = { vrm, mileage, date: search.date, customerReference }

      await companion.value(params)
    },
    async check() {
      try {
        const { type, isVrm, isVin, search } = this
        const { mileage, value } = search
        const data = await autocheck.precheck({ value, type, mileage })
        this.$modal.open('provenance/precheck', {
          serviceType: 'AutoCheck',
          vrm: isVrm ? value : data.vrm,
          vin: isVin ? value : data.vin,
          description: provenance.buildPreDescription(data),
          mileage: data.mileage,
          submitButtonText: 'Run Check',
          instructionsText: 'Choose the AutoCheck report options you would like to include.'
        })
      } catch (err) {
        throw err
      }
    },
    async motorcheck() {
      try {
        const { type, isVrm, isVin, search } = this
        const { mileage, value } = search
        const data = await provenance.precheck({ value, type, mileage })
        this.$modal.open('provenance/precheck', {
          serviceType: 'Motorcheck',
          vrm: isVrm ? value : data.vrm,
          vin: isVin ? value : data.vin,
          description: provenance.buildPreDescription(data),
          mileage: data.mileage,
          submitButtonText: 'Generate Report',
          instructionsText: 'Choose the MotorCheck report options you would like to include.',
          customTitle: `MotorCheck for ${data.vrm}`
        })
      } catch (err) {
        throw err
      }
    },
    fail(message) {
      this.$notify(
        message || 'We currently cannot find any data for this vehicle'
      )
    },
    toggleOption(action) {
      this.options.forEach((option) => {
        option.isSelected = option.action === action
      })
    }
  }
}
</script>

<style lang="sass" scoped>
form.box
  border: solid 1px $border-background
  margin-top: -1px
  border-top-left-radius: 0
.input, .control .button
  border-radius: 5px !important
.button, .input
  height: 3rem !important
.icon.is-right
  height: 3rem
  right: 1em !important
.calendar-colour
  color: $calendar-color
</style>
